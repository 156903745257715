
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Home extends Vue {}

window.setTimeout(function () {
  window.location.href = "https://zeroheight.com/60d194782";
}, 100);
